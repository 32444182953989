import {
  FETCH_LIST_PRODUCTS_REQUEST,
  FETCH_LIST_PRODUCTS_SUCCESS,
  FETCH_LIST_PRODUCTS_FALUIRE,
} from './listProductsTypes'

const initialState = {
  loading: false,
  allProductList: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LIST_PRODUCTS_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LIST_PRODUCTS_SUCCESS : return {
      ...state,
      allProductList: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LIST_PRODUCTS_FALUIRE : return {
      ...state,
      loading: false,
      allProductList: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
