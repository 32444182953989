import {
    GET_VERIFY_EMAIL_REQUEST,
    GET_VERIFY_EMAIL_SUCCESS,
    GET_VERIFY_EMAIL_FALUIRE,

} from "./verifyEmailType"

const initialState = {
    loading: false,
    error: "",
    Getemail: null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_VERIFY_EMAIL_REQUEST: return {
            ...state,
            loading: true,

        }

        case GET_VERIFY_EMAIL_SUCCESS: return {
            ...state,
            Getemail: action.payload,
            error: false,
            loading: false,
        }

        case GET_VERIFY_EMAIL_FALUIRE: return {
            ...state,
            loading: false,
            Getemail: null,
            error: action.payload,
        }

        default: return state
    }
}

export default reducer
