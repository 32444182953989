import {
  FETCH_LIST_TEMPLATE_TYPE_REQUEST,
  FETCH_LIST_TEMPLATE_TYPE_SUCCESS,
  FETCH_LIST_TEMPLATE_TYPE_FALUIRE,



  UPDATE_CREATE_NEW_TEMPLATE_REQUEST,
  UPDATE_CREATE_NEW_TEMPLATE_SUCCESS,
  UPDATE_CREATE_NEW_TEMPLATE_FALUIRE,
} from './listTemplateTypeTypes'

const initialState = {
  loading: false,
  allTemplateTypeListing: null,
  error: '',
  newTemplatedata: []
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LIST_TEMPLATE_TYPE_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_LIST_TEMPLATE_TYPE_SUCCESS: return {
      ...state,
      allTemplateTypeListing: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LIST_TEMPLATE_TYPE_FALUIRE: return {
      ...state,
      loading: false,
      allTemplateTypeListing: null,
      error: action.payload,
    }



    case UPDATE_CREATE_NEW_TEMPLATE_REQUEST: return {
      ...state,
      loading: true,

    }

    case UPDATE_CREATE_NEW_TEMPLATE_SUCCESS: return {
      ...state,
      newTemplatedata: action.payload,
      error: '',
      loading: false,
    }

    case UPDATE_CREATE_NEW_TEMPLATE_FALUIRE: return {
      ...state,
      loading: false,
      newTemplatedata: null,
      error: action.payload,
    }





    default: return state
  }
}

export default reducer
