import {
  GET_FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  GET_FAQ_FALUIRE,

  POST_FAQ_REQUEST,
  POST_FAQ_SUCCESS,
  POST_FAQ_FALUIRE,

  FETCH_FAQ_BY_ID_REQUEST,
  FETCH_FAQ_BY_ID_SUCCESS,
  FETCH_FAQ_BY_ID_FALUIRE,

  UPDATE_FAQ_REQUEST,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FALUIRE,

  DELETE_FAQ_REQUEST,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FALUIRE
} from './addFAQsTypes'

const initialState = {
  loading: false,
  getFAQs: [],
  postFAQs: [],
  FetchBYId: null,
  updateFAQs: null,
  deleteFAQs: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_FAQ_REQUEST: return {
      ...state,
      loading: true,
      getFAQs: null,
    }

    case GET_FAQ_SUCCESS: return {
      ...state,
      getFAQs: action.payload,
      error: '',
      loading: false,
    }

    case GET_FAQ_FALUIRE: return {
      ...state,
      loading: false,
      getFAQs: null,
      error: action.payload,
    }



    case POST_FAQ_REQUEST: return {
      ...state,
      loading: true,

    }

    case POST_FAQ_SUCCESS: return {
      ...state,
      postFAQs: action.payload,
      error: '',
      loading: false,
    }

    case POST_FAQ_FALUIRE: return {
      ...state,
      loading: false,
      postFAQs: null,
      error: action.payload,
    }


    case FETCH_FAQ_BY_ID_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_FAQ_BY_ID_SUCCESS: return {
      ...state,
      FetchBYId: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_FAQ_BY_ID_FALUIRE: return {
      ...state,
      loading: false,
      FetchBYId: null,
      error: action.payload,
    }


    case UPDATE_FAQ_REQUEST: return {
      ...state,
      loading: true,

    }

    case UPDATE_FAQ_SUCCESS: return {
      ...state,
      updateFAQs: action.payload,
      error: '',
      loading: false,
    }

    case UPDATE_FAQ_FALUIRE: return {
      ...state,
      loading: false,
      updateFAQs: null,
      error: action.payload,
    }



    case DELETE_FAQ_REQUEST: return {
      ...state,
      loading: true,

    }

    case DELETE_FAQ_SUCCESS: return {
      ...state,
      deleteFAQs: action.payload,
      error: '',
      loading: false,
    }

    case DELETE_FAQ_FALUIRE: return {
      ...state,
      loading: false,
      deleteFAQs: null,
      error: action.payload,
    }



    default: return state
  }
}

export default reducer
