import {
  GET_COMPLETED_SURVAY_REQUEST,
  GET_COMPLETED_SURVAY_SUCCESS,
  GET_COMPLETED_SURVAY_FAIL,
} from "./completeSurveyType";
const initialState = {
  completedSurvey: false,
  loading: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPLETED_SURVAY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPLETED_SURVAY_SUCCESS:
      return {
        ...state,
        loading: false,
        completedSurvey: action.payload,
      };
    case GET_COMPLETED_SURVAY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
