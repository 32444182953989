export const FETCH_ADD_CATEGORY_REQUEST = 'FETCH_ADD_CATEGORY_REQUEST'
export const FETCH_ADD_CATEGORY_SUCCESS = 'FETCH_ADD_CATEGORY_SUCCESS'
export const FETCH_ADD_CATEGORY_FALUIRE = 'FETCH_ADD_CATEGORY_FALUIRE'

export const FETCH_MANAGE_CATEGORY_REQUEST = 'FETCH_MANAGE_CATEGORY_REQUEST'
export const FETCH_MANAGE_CATEGORY_SUCCESS = 'FETCH_MANAGE_CATEGORY_SUCCESS'
export const FETCH_MANAGE_CATEGORY_FALUIRE = 'FETCH_MANAGE_CATEGORY_FALUIRE'


export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FALUIRE = 'UPDATE_CATEGORY_FALUIRE'



export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FALUIRE = 'DELETE_CATEGORY_FALUIRE'


export const DISABLE_CATEGORY_REQUEST = 'DISABLE_CATEGORY_REQUEST'
export const DISABLE_CATEGORY_SUCCESS = 'DISABLE_CATEGORY_SUCCESS'
export const DISABLE_CATEGORY_FALUIRE = 'DISABLE_CATEGORY_FALUIRE'

