export const FETCH_Survay_CATEGORY_REQUEST = "FETCH_Survay_CATEGORY_REQUEST";
export const FETCH_Survay_CATEGORY_SUCCESS = "FETCH_Survay_CATEGORY_SUCCESS";
export const FETCH_Survay_CATEGORY_FALUIRE = "FETCH_Survay_CATEGORY_FALUIRE";

export const POST_SURVEY_FORM_REQUEST = "POST_SURVEY_FORM_REQUEST";
export const POST_SURVEY_FORM_SUCCESS = "POST_SURVEY_FORM_SUCCESS";
export const POST_SURVEY_FORM_FALUIRE = "POST_SURVEY_FORM_FALUIRE";

/**ACTION TYPE FOR GET TAGS */
export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FALUIRE = "GET_TAGS_FALUIRE";

/**END OF ACION TYPE FOR GET TAGS */

/**ACTION TYPE FOR POST TAGS */
export const POST_TAGS_REQUEST = "POST_TAGS_REQUEST";
export const POST_TAGS_SUCCESS = "POST_TAGS_SUCCESS";
export const POST_TAGS_FALUIRE = "POST_TAGS_FALUIRE";
/**END OF ACTION TYPE FOR POST TAGS */

/**POST Survey Form */

export const POST_SURVEY_REQUEST = "POST_SURVEY_REQUEST";
export const POST_SURVEY_SUCCESS = "POST_SURVEY_SUCCESS";
export const POST_SURVEY_FALUIRE = "POST_SURVEY_FALUIRE";

/**GET Manage Survey */
export const GET_MANAGE_SURVEY_REQUEST = "GET_MANAGE_SURVEY_REQUEST";
export const GET_MANAGE_SURVEY_SUCCESS = "GET_MANAGE_SURVEY_SUCCESS";
export const GET_MANAGE_SURVEY_FALUIRE = "GET_MANAGE_SURVEY_FALUIRE";

/**PUT VISIBILITY */
export const PUT_VISIBILITY_REQUEST = "PUT_VISIBILITY_REQUEST";
export const PUT_VISIBILITY_SUCCESS = "PUT_VISIBILITY_SUCCESS";
export const PUT_VISIBILITY_FALUIRE = "PUT_VISIBILITY_FALUIRE";

// Delete Survay
export const DELETE_MANAGE_SURVAY_REQUEST = "DELETE_MANAGE_SURVAY_REQUEST";
export const DELETE_MANAGE_SURVAY_SUCCESS = "DELETE_MANAGE_SURVAY_SUCCESS";
export const DELETE_MANAGE_SURVAY_FAILURE = "DELETE_MANAGE_SURVAY_FAILURE";

export const GET_COMPLETED_SURVAY_REQUEST = "GET_COMPLETED_SURVAY_REQUEST";
export const GET_COMPLETED_SURVAY_SUCCESS = "GET_COMPLETED_SURVAY_SUCCESS";
export const GET_COMPLETED_SURVAY_FAIL = "GET_COMPLETED_SURVAY_FAIL";

//Ad Survey To Home Page
export const AD_SURVEY_HOME_PAGE_REQUEST = "AD_SURVEY_HOME_REQUEST";
export const AD_SURVEY_HOME_SUCCESS  = "AD_SURVEY_HOME_SUCCESS";
export const AD_SURVEY_HOME_PAGE_FALUIRE ="AD_SURVEY_HOME_FALUIRE";

//Get Survay Data
export const GET_SURVEY_REQUEST = "GET_SURVEY_REQUEST";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";

//Get Survay Form
export const GET_SURVEY_FORM_REQUEST = "GET_SURVEY_FORM_REQUEST";
export const GET_SURVEY_FORM_SUCCESS = "GET_SURVEY_FORM_SUCCESS";
export const GET_SURVEY_FORM_FAILED = "GET_SURVEY_FORM_FAILED";


//Get Survey Tags
export const GET_SURVEY_TAGS_REQUEST = "GET_SURVEY_TAGS_REQUEST";
export const GET_SURVEY_TAGS_SUCCESS = "GET_SURVEY_TAGS_SUCCESS";
export const GET_SURVEY_TAGS_FAILED = "GET_SURVEY_TAGS_FAILED";