
export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST'
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS'
export const GET_FAQ_FALUIRE = 'GET_FAQ_FALUIRE'



export const POST_FAQ_REQUEST = 'POST_FAQ_REQUEST'
export const POST_FAQ_SUCCESS = 'POST_FAQ_SUCCESS'
export const POST_FAQ_FALUIRE = 'POST_FAQ_FALUIRE'


export const FETCH_FAQ_BY_ID_REQUEST = 'FETCH_FAQ_BY_ID_REQUEST'
export const FETCH_FAQ_BY_ID_SUCCESS = 'FETCH_FAQ_BY_ID_SUCCESS'
export const FETCH_FAQ_BY_ID_FALUIRE = 'FETCH_FAQ_BY_ID_FALUIRE'

export const UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST'
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS'
export const UPDATE_FAQ_FALUIRE = 'UPDATE_FAQ_FALUIRE'


export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST'
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS'
export const DELETE_FAQ_FALUIRE = 'DELETE_FAQ_FALUIRE'



