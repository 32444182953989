/**Action Type For POST ARTICLE */
export const POST_ARTICLE_REQUEST = 'POST_ARTICLE_REQUEST'
export const POST_ARTICLE_SUCCESS = 'POST_ARTICLE_SUCCESS'
export const POST_ARTICLE_FALUIRE = 'POST_ARTICLE_FALUIRE'

/** Action Type For GET ARTICLE*/
export const GET_ARTICLE_REQUEST = 'GET_ARTICLE_REQUEST'
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS'
export const GET_ARTICLE_FALUIRE = 'GET_ARTICLE_FALUIRE'

/** Action Type For DELETE ARTICLE*/
export const DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FALUIRE = 'DELETE_ARTICLE_FALUIRE'


/** Action Type FOR GETBYID ARTICLE*/
export const GET_BY_ID_EDIT_REQUEST = 'GET_BY_ID_EDIT_REQUEST'
export const GET_BY_ID_EDIT_SUCCESS = 'GET_BY_ID_EDIT_SUCCESS'
export const GET_BY_ID_EDIT_FALUIRE = 'GET_BY_ID_EDIT_FALUIRE'


/** Action Type FOR GETBYID ARTICLE*/
export const PUT_ARTICLE_REQUEST = 'PUT_ARTICLE_REQUEST'
export const PUT_ARTICLE_SUCCESS = 'PUT_ARTICLE_SUCCESS'
export const PUT_ARTICLE_FALUIRE = 'PUT_ARTICLE_FALUIRE'


