exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-500-index-js": () => import("./../../../src/pages/500/index.js" /* webpackChunkName: "component---src-pages-500-index-js" */),
  "component---src-pages-aboutus-index-js": () => import("./../../../src/pages/aboutus/index.js" /* webpackChunkName: "component---src-pages-aboutus-index-js" */),
  "component---src-pages-account-verification-js": () => import("./../../../src/pages/account/verification.js" /* webpackChunkName: "component---src-pages-account-verification-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-index-no-articles-js": () => import("./../../../src/pages/articles/index_no_articles.js" /* webpackChunkName: "component---src-pages-articles-index-no-articles-js" */),
  "component---src-pages-articles-index-orig-js": () => import("./../../../src/pages/articles/index_orig.js" /* webpackChunkName: "component---src-pages-articles-index-orig-js" */),
  "component---src-pages-bussiness-info-js": () => import("./../../../src/pages/BussinessInfo.js" /* webpackChunkName: "component---src-pages-bussiness-info-js" */),
  "component---src-pages-dashboard-activity-js": () => import("./../../../src/pages/dashboard/activity.js" /* webpackChunkName: "component---src-pages-dashboard-activity-js" */),
  "component---src-pages-dashboard-admin-adminuseracess-js": () => import("./../../../src/pages/dashboard/admin/adminuseracess.js" /* webpackChunkName: "component---src-pages-dashboard-admin-adminuseracess-js" */),
  "component---src-pages-dashboard-admin-ads-manage-js": () => import("./../../../src/pages/dashboard/admin/ads/manage.js" /* webpackChunkName: "component---src-pages-dashboard-admin-ads-manage-js" */),
  "component---src-pages-dashboard-admin-ads-new-js": () => import("./../../../src/pages/dashboard/admin/ads/new.js" /* webpackChunkName: "component---src-pages-dashboard-admin-ads-new-js" */),
  "component---src-pages-dashboard-admin-ads-update-[id]-js": () => import("./../../../src/pages/dashboard/admin/ads/update/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-ads-update-[id]-js" */),
  "component---src-pages-dashboard-admin-campaign-index-js": () => import("./../../../src/pages/dashboard/admin/campaign/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-campaign-index-js" */),
  "component---src-pages-dashboard-admin-campaign-leads-index-js": () => import("./../../../src/pages/dashboard/admin/campaign/leads/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-campaign-leads-index-js" */),
  "component---src-pages-dashboard-admin-category-manage-index-js": () => import("./../../../src/pages/dashboard/admin/category/manage/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-category-manage-index-js" */),
  "component---src-pages-dashboard-admin-category-new-js": () => import("./../../../src/pages/dashboard/admin/category/new.js" /* webpackChunkName: "component---src-pages-dashboard-admin-category-new-js" */),
  "component---src-pages-dashboard-admin-category-update-[id]-js": () => import("./../../../src/pages/dashboard/admin/category/update/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-category-update-[id]-js" */),
  "component---src-pages-dashboard-admin-company-about-js": () => import("./../../../src/pages/dashboard/admin/company/about.js" /* webpackChunkName: "component---src-pages-dashboard-admin-company-about-js" */),
  "component---src-pages-dashboard-admin-company-privacypolicy-js": () => import("./../../../src/pages/dashboard/admin/company/privacypolicy.js" /* webpackChunkName: "component---src-pages-dashboard-admin-company-privacypolicy-js" */),
  "component---src-pages-dashboard-admin-company-termandcondition-js": () => import("./../../../src/pages/dashboard/admin/company/termandcondition.js" /* webpackChunkName: "component---src-pages-dashboard-admin-company-termandcondition-js" */),
  "component---src-pages-dashboard-admin-edit-article-[id]-js": () => import("./../../../src/pages/dashboard/admin/editArticle/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-edit-article-[id]-js" */),
  "component---src-pages-dashboard-admin-edit-user-profile-[id]-js": () => import("./../../../src/pages/dashboard/admin/editUserProfile/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-edit-user-profile-[id]-js" */),
  "component---src-pages-dashboard-admin-faq-js": () => import("./../../../src/pages/dashboard/admin/faq.js" /* webpackChunkName: "component---src-pages-dashboard-admin-faq-js" */),
  "component---src-pages-dashboard-admin-game-manage-index-js": () => import("./../../../src/pages/dashboard/admin/game/manage/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-game-manage-index-js" */),
  "component---src-pages-dashboard-admin-game-new-js": () => import("./../../../src/pages/dashboard/admin/game/new.js" /* webpackChunkName: "component---src-pages-dashboard-admin-game-new-js" */),
  "component---src-pages-dashboard-admin-game-update-[id]-js": () => import("./../../../src/pages/dashboard/admin/game/update/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-game-update-[id]-js" */),
  "component---src-pages-dashboard-admin-index-js": () => import("./../../../src/pages/dashboard/admin/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-index-js" */),
  "component---src-pages-dashboard-admin-manage-article-index-js": () => import("./../../../src/pages/dashboard/admin/manageArticle/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-manage-article-index-js" */),
  "component---src-pages-dashboard-admin-platform-manage-index-js": () => import("./../../../src/pages/dashboard/admin/platform/manage/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-platform-manage-index-js" */),
  "component---src-pages-dashboard-admin-platform-new-js": () => import("./../../../src/pages/dashboard/admin/platform/new.js" /* webpackChunkName: "component---src-pages-dashboard-admin-platform-new-js" */),
  "component---src-pages-dashboard-admin-platform-update-[id]-js": () => import("./../../../src/pages/dashboard/admin/platform/update/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-platform-update-[id]-js" */),
  "component---src-pages-dashboard-admin-products-index-js": () => import("./../../../src/pages/dashboard/admin/products/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-products-index-js" */),
  "component---src-pages-dashboard-admin-products-new-js": () => import("./../../../src/pages/dashboard/admin/products/new.js" /* webpackChunkName: "component---src-pages-dashboard-admin-products-new-js" */),
  "component---src-pages-dashboard-admin-products-update-[id]-js": () => import("./../../../src/pages/dashboard/admin/products/update/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-products-update-[id]-js" */),
  "component---src-pages-dashboard-admin-profile-js": () => import("./../../../src/pages/dashboard/admin/profile.js" /* webpackChunkName: "component---src-pages-dashboard-admin-profile-js" */),
  "component---src-pages-dashboard-admin-report-js": () => import("./../../../src/pages/dashboard/admin/report.js" /* webpackChunkName: "component---src-pages-dashboard-admin-report-js" */),
  "component---src-pages-dashboard-admin-site-index-js": () => import("./../../../src/pages/dashboard/admin/site/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-site-index-js" */),
  "component---src-pages-dashboard-admin-site-transaction-index-js": () => import("./../../../src/pages/dashboard/admin/site/transaction/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-site-transaction-index-js" */),
  "component---src-pages-dashboard-admin-survey-[id]-js": () => import("./../../../src/pages/dashboard/admin/survey/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-survey-[id]-js" */),
  "component---src-pages-dashboard-admin-survey-manage-index-js": () => import("./../../../src/pages/dashboard/admin/survey/manage/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-survey-manage-index-js" */),
  "component---src-pages-dashboard-admin-survey-new-js": () => import("./../../../src/pages/dashboard/admin/survey/new.js" /* webpackChunkName: "component---src-pages-dashboard-admin-survey-new-js" */),
  "component---src-pages-dashboard-admin-template-create-js": () => import("./../../../src/pages/dashboard/admin/template/create.js" /* webpackChunkName: "component---src-pages-dashboard-admin-template-create-js" */),
  "component---src-pages-dashboard-admin-template-edit-[id]-js": () => import("./../../../src/pages/dashboard/admin/template/edit/[id].js" /* webpackChunkName: "component---src-pages-dashboard-admin-template-edit-[id]-js" */),
  "component---src-pages-dashboard-admin-template-manage-index-js": () => import("./../../../src/pages/dashboard/admin/template/manage/index.js" /* webpackChunkName: "component---src-pages-dashboard-admin-template-manage-index-js" */),
  "component---src-pages-dashboard-admin-writearticle-js": () => import("./../../../src/pages/dashboard/admin/writearticle.js" /* webpackChunkName: "component---src-pages-dashboard-admin-writearticle-js" */),
  "component---src-pages-dashboard-contact-user-js": () => import("./../../../src/pages/dashboard/contact/user.js" /* webpackChunkName: "component---src-pages-dashboard-contact-user-js" */),
  "component---src-pages-dashboard-games-index-js": () => import("./../../../src/pages/dashboard/games/index.js" /* webpackChunkName: "component---src-pages-dashboard-games-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-product-listing-index-js": () => import("./../../../src/pages/dashboard/product/listing/index.js" /* webpackChunkName: "component---src-pages-dashboard-product-listing-index-js" */),
  "component---src-pages-dashboard-profile-update-index-js": () => import("./../../../src/pages/dashboard/profile/update/index.js" /* webpackChunkName: "component---src-pages-dashboard-profile-update-index-js" */),
  "component---src-pages-dashboard-profile-user-index-js": () => import("./../../../src/pages/dashboard/profile/user/index.js" /* webpackChunkName: "component---src-pages-dashboard-profile-user-index-js" */),
  "component---src-pages-dashboard-survey-index-js": () => import("./../../../src/pages/dashboard/survey/index.js" /* webpackChunkName: "component---src-pages-dashboard-survey-index-js" */),
  "component---src-pages-dashboard-surveyphase-two-[id]-js": () => import("./../../../src/pages/dashboard/surveyphaseTwo/[id].js" /* webpackChunkName: "component---src-pages-dashboard-surveyphase-two-[id]-js" */),
  "component---src-pages-email-verification-js": () => import("./../../../src/pages/email/verification.js" /* webpackChunkName: "component---src-pages-email-verification-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-admin-index-js": () => import("./../../../src/pages/login-admin/index.js" /* webpackChunkName: "component---src-pages-login-admin-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-password-checkmail-js": () => import("./../../../src/pages/password/checkmail.js" /* webpackChunkName: "component---src-pages-password-checkmail-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password/reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-privacypolicies-index-js": () => import("./../../../src/pages/privacypolicies/index.js" /* webpackChunkName: "component---src-pages-privacypolicies-index-js" */),
  "component---src-pages-progressbar-js": () => import("./../../../src/pages/progressbar.js" /* webpackChunkName: "component---src-pages-progressbar-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-success-mask-js": () => import("./../../../src/pages/success-mask.js" /* webpackChunkName: "component---src-pages-success-mask-js" */),
  "component---src-pages-termsandconditions-index-js": () => import("./../../../src/pages/termsandconditions/index.js" /* webpackChunkName: "component---src-pages-termsandconditions-index-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-verification-accountsetup-js": () => import("./../../../src/pages/verification/accountsetup.js" /* webpackChunkName: "component---src-pages-verification-accountsetup-js" */),
  "component---src-pages-verification-token-js": () => import("./../../../src/pages/verification/token.js" /* webpackChunkName: "component---src-pages-verification-token-js" */),
  "component---src-templates-article-details-js": () => import("./../../../src/templates/article-details.js" /* webpackChunkName: "component---src-templates-article-details-js" */)
}

