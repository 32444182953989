import {
  FETCH_ADD_CATEGORY_REQUEST,
  FETCH_ADD_CATEGORY_SUCCESS,
  FETCH_ADD_CATEGORY_FALUIRE,
  FETCH_MANAGE_CATEGORY_REQUEST,
  FETCH_MANAGE_CATEGORY_SUCCESS,
  FETCH_MANAGE_CATEGORY_FALUIRE,

  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FALUIRE,


  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FALUIRE,

  DISABLE_CATEGORY_REQUEST,
  DISABLE_CATEGORY_SUCCESS,
  DISABLE_CATEGORY_FALUIRE
} from './addcategoryTypes'

const initialState = {
  loading: false,
  isAddCategorySuccess: null,
  error: '',
  isManagecategory: null,
  updateCategory: null,
  deletecategory: null,
  disablecategory: null
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_ADD_CATEGORY_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_ADD_CATEGORY_SUCCESS: return {
      ...state,
      isAddCategorySuccess: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_ADD_CATEGORY_FALUIRE: return {
      ...state,
      loading: false,
      isAddCategorySuccess: null,
      error: action.payload,
    }

    case FETCH_MANAGE_CATEGORY_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_MANAGE_CATEGORY_SUCCESS: return {
      ...state,
      isManagecategory: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_MANAGE_CATEGORY_FALUIRE: return {
      ...state,
      loading: false,
      isManagecategory: null,
      error: action.payload,
    }

    case UPDATE_CATEGORY_REQUEST: return {
      ...state,
      loading: true,

    }

    case UPDATE_CATEGORY_SUCCESS: return {
      ...state,
      updateCategory: action.payload,
      error: '',
      loading: false,
    }

    case UPDATE_CATEGORY_FALUIRE: return {
      ...state,
      loading: false,
      updateCategory: null,
      error: action.payload,
    }



    case DELETE_CATEGORY_REQUEST: return {
      ...state,
      loading: true,

    }

    case DELETE_CATEGORY_SUCCESS: return {
      ...state,
      deletecategory: action.payload,
      error: '',
      loading: false,
    }

    case DELETE_CATEGORY_FALUIRE: return {
      ...state,
      loading: false,
      deletecategory: null,
      error: action.payload,
    }


    case DISABLE_CATEGORY_REQUEST: return {
      ...state,
      loading: true,

    }

    case DISABLE_CATEGORY_SUCCESS: return {
      ...state,
      disablecategory: action.payload,
      error: '',
      loading: false,
    }

    case DISABLE_CATEGORY_FALUIRE: return {
      ...state,
      loading: false,
      disablecategory: null,
      error: action.payload,
    }



    default: return state
  }
}

export default reducer
