export const FETCH_ADD_TEMPLATE_REQUEST = 'FETCH_ADD_TEMPLATE_REQUEST'
export const FETCH_ADD_TEMPLATE_SUCCESS = 'FETCH_ADD_TEMPLATE_SUCCESS'
export const FETCH_ADD_TEMPLATE_FALUIRE = 'FETCH_ADD_TEMPLATE_FALUIRE'

export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST'
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS'
export const GET_TEMPLATE_FALUIRE = 'GET_TEMPLATE_FALUIRE'

export const GET_BY_ID_TEMPLATE_REQUEST = 'GET_BY_ID_TEMPLATE_REQUEST'
export const GET_BY_ID_TEMPLATE_SUCCESS = 'GET_BY_ID_TEMPLATE_SUCCESS'
export const GET_BY_ID_TEMPLATE_FALUIRE = 'GET_BY_ID_TEMPLATE_FALUIRE'

export const UPDATE_Template_REQUEST = 'UPDATE_Template_REQUEST'
export const UPDATE_Template_SUCCESS = 'UPDATE_Template_SUCCESS'
export const UPDATE_Template_FALUIRE = 'UPDATE_Template_FALUIRE'

export const DELETE_Template_REQUEST = 'DELETE_Template_REQUEST'
export const DELETE_Template_SUCCESS = 'DELETE_Template_SUCCESS'
export const DELETE_Template_FALUIRE = 'DELETE_Template_FALUIRE'

/**PUT VISIBILITY */
export const PUT_VISIBILITY_REQUEST = "PUT_VISIBILITY_REQUEST";
export const PUT_VISIBILITY_SUCCESS = "PUT_VISIBILITY_SUCCESS";
export const PUT_VISIBILITY_FALUIRE = "PUT_VISIBILITY_FALUIRE";