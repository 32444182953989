import { createStore as reduxCreateStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducers";
import localStorage from "localStorage";

let initState = {
  userInfo: "",
};

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("loginInfo", serializedState);
  } catch (e) {
    console.log(e);
  }
}

//this method is used for the reading of the value form the local storage
function readFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("loginInfo");
    if (serializedState == null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;

  }
}

const cartStateFromLocalStorage = readFromLocalStorage();
if (cartStateFromLocalStorage) {
  initState = Object.assign(initState, cartStateFromLocalStorage);
}

const middleware = [thunk]

const store = reduxCreateStore(
  rootReducer, 
  initState,
  compose(
    applyMiddleware(...middleware),
      // Enable Redux DevTools Extension if available
      typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
);

store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage({
    userInfo: state.userInfo,
  });
});

export default store;
