// src/utils/analytics.js
const DEBUG = process.env.NODE_ENV === 'development';

const debugLog = (message, data) => {
  if (DEBUG) {
    console.log(`[Analytics Debug] ${message}`, data);
  }
};

export const trackPageView = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'pageview',
      page_path: window.location.pathname,
      page_title: document.title,
      page_url: window.location.href
    };
    debugLog('Tracking pageview:', eventData);
    window.dataLayer.push(eventData);
  } else {
    debugLog('DataLayer not available');
  }
};

export const trackLogin = (userId) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'login',
      user_id: userId,
      timestamp: new Date().toISOString()
    };
    debugLog('Tracking login:', eventData);
    window.dataLayer.push(eventData);
  } else {
    debugLog('DataLayer not available');
  }
};

export const trackLoginFailure = (errorMessage) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'login_failure',
      error_message: errorMessage,
      timestamp: new Date().toISOString()
    };
    debugLog('Tracking login failure:', eventData);
    window.dataLayer.push(eventData);
  } else {
    debugLog('DataLayer not available');
  }
};

export const trackNewUser = (userData) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'signup',
      user_id: userData.userId,
      user_type: userData.userType,
      user_email: userData.email,
      timestamp: new Date().toISOString()
    };
    debugLog('Tracking new user:', eventData);
    window.dataLayer.push(eventData);
  } else {
    debugLog('DataLayer not available');
  }
};

export const trackSignUpFailure = (errorMessage) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const eventData = {
      event: 'signup_failure',
      error_message: errorMessage,
      timestamp: new Date().toISOString()
    };
    debugLog('Tracking signup failure:', eventData);
    window.dataLayer.push(eventData);
  } else {
    debugLog('DataLayer not available');
  }
};
