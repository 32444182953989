// import { NULL } from 'node-sass'
import {
    GET_PRIVACY_POLICY_REQUEST,
    GET_PRIVACY_POLICY_SUCCESS,
    GET_PRIVACY_POLICY_FALUIRE,


    GET_ABOUT_US_REQUEST,
    GET_ABOUT_US_SUCCESS,
    GET_ABOUT_US_FALUIRE,


    GET_TERM_CONDITION_REQUEST,
    GET_TERM_CONDITION_SUCCESS,
    GET_TERM_CONDITION_FALUIRE,

    PUT_TERM_CONDITION_REQUEST,
    PUT_TERM_CONDITION_SUCCESS,
    PUT_TERM_CONDITION_FALUIRE,

    PUT_PRIVACY_POLICY_REQUEST,
    PUT_PRIVACY_POLICY_SUCCESS,
    PUT_PRIVACY_POLICY_FALUIRE,

    PUT_ABOUT_US_REQUEST,
    PUT_ABOUT_US_SUCCESS,
    PUT_ABOUT_US_FALUIRE,
} from './companyDetailsTypes'

const initialState = {
    loading: false,
    privacyPolicy: null,
    aboutUs: null,
    termCondition: null,
    putTermCondition: null,
    putprivacyPolicy: null,
    putAboutUs: null,
    error: '',

}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_PRIVACY_POLICY_REQUEST: return {
            ...state,
            loading: true,

        }

        case GET_PRIVACY_POLICY_SUCCESS: return {
            ...state,
            privacyPolicy: action.payload,
            error: '',
            loading: false,
        }

        case GET_PRIVACY_POLICY_FALUIRE: return {
            ...state,
            loading: false,
            privacyPolicy: null,
            error: action.payload,
        }


        case GET_ABOUT_US_REQUEST: return {
            ...state,
            loading: true,

        }

        case GET_ABOUT_US_SUCCESS: return {
            ...state,
            aboutUs: action.payload,
            error: '',
            loading: false,
        }

        case GET_ABOUT_US_FALUIRE: return {
            ...state,
            loading: false,
            aboutUs: null,
            error: action.payload,
        }




        case GET_TERM_CONDITION_REQUEST: return {
            ...state,
            loading: true,

        }

        case GET_TERM_CONDITION_SUCCESS: return {
            ...state,
            termCondition: action.payload,
            error: '',
            loading: false,
        }

        case GET_TERM_CONDITION_FALUIRE: return {
            ...state,
            loading: false,
            termCondition: null,
            error: action.payload,
        }

        case PUT_TERM_CONDITION_REQUEST: return {
            ...state,
            loading: true,

        }

        case PUT_TERM_CONDITION_SUCCESS: return {
            ...state,
            putTermCondition: action.payload,
            error: '',
            loading: false,
        }

        case PUT_TERM_CONDITION_FALUIRE: return {
            ...state,
            loading: false,
            putTermCondition: null,
            error: action.payload,
        }



        case PUT_PRIVACY_POLICY_REQUEST: return {
            ...state,
            loading: true,

        }

        case PUT_PRIVACY_POLICY_SUCCESS: return {
            ...state,
            putprivacyPolicy: action.payload,
            error: '',
            loading: false,
        }

        case PUT_PRIVACY_POLICY_FALUIRE: return {
            ...state,
            loading: false,
            putprivacyPolicy: null,
            error: action.payload,
        }





        case PUT_ABOUT_US_REQUEST: return {
            ...state,
            loading: true,

        }

        case PUT_ABOUT_US_SUCCESS: return {
            ...state,
            putAboutUs: action.payload,
            error: '',
            loading: false,
        }

        case PUT_ABOUT_US_FALUIRE: return {
            ...state,
            loading: false,
            putAboutUs: null,
            error: action.payload,
        }




        default: return state
    }
}

export default reducer
