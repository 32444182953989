import { combineReducers } from "redux";
import userInfo from "./login/loginReducer";
import allCategoryListing from "./listCategory/listCategoryReducer";
import isAddCategorySuccess from "./addcategory/addcategoryReducer";
import allProductList from "./listProducts/listProductsReducer";
import allCategoryList from "./categoryTypeList/categoryTypeListReducer";
import allPlatformList from "./platformSelectionList/platformSelectionListReducer";
import allBrandListing from "./listBrandPlatform/listBrandPlatformReducer";
import isAddProductSuccess from "./addProduct/addProductReducer";
import addPlatformBrand from "./addBrandPlatform/addBrandPlatformReducer";
import allFunnelListing from "./listFunnel/listFunnelReducer";
import allTemplateTypeListing from "./listTemplateType/listTemplateTypeReducer";
import registerUserInfo from "./register/registerReducer";
import categoryByID from "./getCategoryByID/getCategoryByIDReducer";
import addTemplates from "./addTemplate/addTemplateReducer";
import contactUSPAgeContent from "./getContactUsPageContent/getContactUsPageContentReducer";
import aboutUSPAgeContent from "./getAboutUsPageContent/getAboutUsPageContentReducer";
import contactUs from "./contactUs/contactUsReducer";
import articlePAgeContent from "./getArticleUsPageContent/getArticleUsPageContentReducer";
import privacyPolicyPageContent from "./getPrivacyPolicyPageContent/getPrivacyPolicyPageContentReducer";
import getCategoryStatics from "./getCategoryStatics/getCategoryStaticsReducer";
import getSurvay from "./addSurvay/addsurvayReducer";
import getCompnayDetails from "./companyDetails/companyDetailsReducer";
import fetchFAQS from "./addFAQs/addFAQsReducer";
import addArticle from "./addArticle/addArticleReducer";
import particularProductInfo from "./getProductByID/getProductByIDReducer";
import userAddSurvey from "./useraddSurvay/useraddsurvayReducer";
import ParticipatedSurvay from "./participateSurvey/participateSurveyReducer";
import addGame from "./addGame/addGameReducer";
import EmailVerify from "./verifyemail/verifyEmailReducer";
import feedbackSurvey from "./feedbackSurvey/feedbackSurveyReducer";
import DeleteProduct from "./deleteProduct/deleteProductReducer";
import Profile from "./userProfile/profileReducer";
import CompleteProfile from "./getCompleteSurvey/completeSurveyReducer";

export default combineReducers({
  allProductList,
  contactUs,
  particularProductInfo,
  addTemplates,
  privacyPolicyPageContent,
  aboutUSPAgeContent,
  isAddProductSuccess,
  articlePAgeContent,
  categoryByID,
  allTemplateTypeListing,
  contactUSPAgeContent,
  registerUserInfo,
  allFunnelListing,
  allPlatformList,
  allCategoryList,
  userInfo,
  allCategoryListing,
  isAddCategorySuccess,
  allBrandListing,
  addPlatformBrand,
  getCategoryStatics,
  getSurvay,
  getCompnayDetails,
  fetchFAQS,
  addArticle,
  userAddSurvey,
  ParticipatedSurvay,
  addGame,
  EmailVerify,
  feedbackSurvey,
  DeleteProduct,
  Profile,
  CompleteProfile,
});
