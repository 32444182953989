import {
    POST_ARTICLE_REQUEST,
    POST_ARTICLE_SUCCESS,
    POST_ARTICLE_FALUIRE,

    GET_ARTICLE_REQUEST,
    GET_ARTICLE_SUCCESS,
    GET_ARTICLE_FALUIRE,

    GET_BY_ID_EDIT_REQUEST,
    GET_BY_ID_EDIT_SUCCESS,
    GET_BY_ID_EDIT_FALUIRE

} from './addArticleType'

const initialState = {
    loading: false,
    error: false,
    PostArticle: [],
    getArticle: [],
    getByArticleid: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case POST_ARTICLE_REQUEST: return {
            ...state,
            loading: true,

        }

        case POST_ARTICLE_SUCCESS: return {
            ...state,
            list: action.payload,
            error: false,
            loading: false,
        }

        case POST_ARTICLE_FALUIRE: return {
            ...state,
            loading: false,
            list: null,
            error: action.payload,
        }


        case GET_ARTICLE_REQUEST: return {
            ...state,
            loading: true,

        }

        case GET_ARTICLE_SUCCESS: return {
            ...state,
            getArticle: action.payload,
            error: false,
            loading: false,
        }

        case GET_ARTICLE_FALUIRE: return {
            ...state,
            loading: false,
            getArticle: null,
            error: action.payload,
        }



        case GET_BY_ID_EDIT_REQUEST: return {
            ...state,
            loading: true,

        }

        case GET_BY_ID_EDIT_SUCCESS: return {
            ...state,
            getByArticleid: action.payload,
            error: false,
            loading: false,
        }

        case GET_BY_ID_EDIT_FALUIRE: return {
            ...state,
            loading: false,
            getByArticleid: null,
            error: action.payload,
        }







        default: return state
    }
}

export default reducer
