export const FETCH_ADD_PRODUCT_REQUEST = 'FETCH_ADD_PRODUCT_REQUEST'
export const FETCH_ADD_PRODUCT_SUCCESS = 'FETCH_ADD_PRODUCT_SUCCESS'
export const FETCH_ADD_PRODUCT_FALUIRE = 'FETCH_ADD_PRODUCT_FALUIRE'


export const GET_MANAGE_PLATFORM_REQUEST = 'GET_MANAGE_PLATFORM_REQUEST'
export const GET_MANAGE_PLATFORM_SUCCESS = 'GET_MANAGE_PLATFORM_SUCCESS'
export const GET_MANAGE_PLATFORM_FALUIRE = 'GET_MANAGE_PLATFORM_FALUIRE'

export const UPDATE_PRODUCT_PLATFORM_REQUEST = 'UPDATE_PRODUCT_PLATFORM_REQUEST'
export const UPDATE_PRODUCT_PLATFORM_SUCCESS = 'UPDATE_PRODUCT_PLATFORM_SUCCESS'
export const UPDATE_PRODUCT_PLATFORM_FALUIRE = 'UPDATE_PRODUCT_PLATFORM_FALUIRE'

/**PUT VISIBILITY */
export const PUT_VISIBILITY_REQUEST = "PUT_VISIBILITY_REQUEST";
export const PUT_VISIBILITY_SUCCESS = "PUT_VISIBILITY_SUCCESS";
export const PUT_VISIBILITY_FALUIRE = "PUT_VISIBILITY_FALUIRE";