import {
  GET_PARTICIPATE_SURVEY_REQUEST,
  GET_PARTICIPATE_SURVEY_SUCCESS,
  GET_PARTICIPATE_SURVEY_FALUIRE


} from './participatesurvayTypes'

const initialState = {
  loading: false,
  getParticipated: [],
  error: false,


}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPATE_SURVEY_REQUEST: return {
      ...state,
      loading: true,

    }

    case GET_PARTICIPATE_SURVEY_SUCCESS: 
    return {
      ...state,
      getParticipated: action.payload,
      error: false,
      loading: false,
    }

    case GET_PARTICIPATE_SURVEY_FALUIRE: return {
      ...state,
      loading: false,
      getParticipated: null,
      error: action.payload,
    }


    default: return state
  }
}

export default reducer
