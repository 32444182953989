import {
  POST_FEEDBACK_SURVEY_REQUEST,
  POST_FEEDBACK_SURVEY_SUCCESS,
  POST_FEEDBACK_SURVEY_FALUIRE


} from './feedbacksurvayTypes'

const initialState = {
  loading: false,
  postfeedback: null,
  error: false,


}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case POST_FEEDBACK_SURVEY_REQUEST: return {
      ...state,
      loading: true,

    }

    case POST_FEEDBACK_SURVEY_SUCCESS: return {
      ...state,
      postfeedback: action.payload,
      error: false,
      loading: false,
    }

    case POST_FEEDBACK_SURVEY_FALUIRE: return {
      ...state,
      loading: false,
      postfeedback: null,
      error: action.payload,
    }


    default: return state
  }
}

export default reducer
