import {
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FALUIRE,
  } from './deleteProductType'
  
  const initialState = {
    loading: false,
    deleteProduct: null,
    error: '',
  }
  
  const reducer = (state = initialState, action) => {
  
    switch (action.type) {
      case DELETE_PRODUCT_REQUEST : return {
        ...state,
        loading: true,
  
      }
  
      case DELETE_PRODUCT_SUCCESS : return {
        ...state,
        deleteProduct: action.payload,
        error: '',
        loading: false,
      }
  
      case DELETE_PRODUCT_FALUIRE : return {
        ...state,
        loading: false,
        deleteProduct: null,
        error: action.payload,
      }
      default : return state
    }
  }
  
  export default reducer
  