import {
    FETCH_PRODUCT_TRAIL_REQUEST,
    FETCH_PRODUCT_TRAIL_SUCCESS,
    FETCH_PRODUCT_TRAIL_FALUIRE,
    FETCH_SURVAY_REQUEST,
    FETCH_SURVAY_SUCCESS,
    FETCH_SURVAY_FALUIRE,

    FETCH_GAME_REQUEST,
    FETCH_GAME_SUCCESS,
    FETCH_GAME_FALUIRE,
} from './getCategoryStaticsType'

const initialState = {
    loading: false,
    productTrail: [],
    survay: [],
    game: [],
    error: '',
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_PRODUCT_TRAIL_REQUEST: return {
            ...state,
            loading: true,

        }

        case FETCH_PRODUCT_TRAIL_SUCCESS: return {
            ...state,
            productTrail: action.payload,
            error: '',
            loading: false,
        }

        case FETCH_PRODUCT_TRAIL_FALUIRE: return {
            ...state,
            loading: false,
            survay: null,
            error: action.payload,
        }

        case FETCH_SURVAY_REQUEST: return {
            ...state,
            loading: true,

        }

        case FETCH_SURVAY_SUCCESS: return {
            ...state,
            survay: action.payload,
            error: '',
            loading: false,
        }

        case FETCH_SURVAY_FALUIRE: return {
            ...state,
            loading: false,
            survay: null,
            error: action.payload,
        }



        case FETCH_GAME_REQUEST: return {
            ...state,
            loading: true,

        }

        case FETCH_GAME_SUCCESS: return {
            ...state,
            game: action.payload,
            error: '',
            loading: false,
        }

        case FETCH_GAME_FALUIRE: return {
            ...state,
            loading: false,
            game: null,
            error: action.payload,
        }



        default: return state
    }
}

export default reducer
