import {
  FETCH_ADD_PRODUCT_REQUEST,
  FETCH_ADD_PRODUCT_SUCCESS,
  FETCH_ADD_PRODUCT_FALUIRE,

  GET_MANAGE_PLATFORM_REQUEST,
  GET_MANAGE_PLATFORM_SUCCESS,
  GET_MANAGE_PLATFORM_FALUIRE,

  UPDATE_PRODUCT_PLATFORM_REQUEST,
  UPDATE_PRODUCT_PLATFORM_SUCCESS,
  UPDATE_PRODUCT_PLATFORM_FALUIRE,

  PUT_VISIBILITY_REQUEST,
  PUT_VISIBILITY_SUCCESS,
  PUT_VISIBILITY_FALUIRE,
} from './addProductTypes'

const initialState = {
  loading: false,
  isAddProductSuccess: null,
  getManagePlatform: [],
  updateProduct: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_ADD_PRODUCT_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_ADD_PRODUCT_SUCCESS: return {
      ...state,
      isAddProductSuccess: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_ADD_PRODUCT_FALUIRE: return {
      ...state,
      loading: false,
      isAddProductSuccess: null,
      error: action.payload,
    }



    case GET_MANAGE_PLATFORM_REQUEST: return {
      ...state,
      loading: true,

    }

    case GET_MANAGE_PLATFORM_SUCCESS: return {
      ...state,
      getManagePlatform: action.payload,
      error: '',
      loading: false,
    }

    case GET_MANAGE_PLATFORM_FALUIRE: return {
      ...state,
      loading: false,
      getManagePlatform: null,
      error: action.payload,
    }


    case UPDATE_PRODUCT_PLATFORM_REQUEST: return {
      ...state,
      loading: true,

    }

    case UPDATE_PRODUCT_PLATFORM_SUCCESS: return {
      ...state,
      updateProduct: action.payload,
      error: '',
      loading: false,
    }

    case UPDATE_PRODUCT_PLATFORM_FALUIRE: return {
      ...state,
      loading: false,
      updateProduct: null,
      error: action.payload,
    }

    case PUT_VISIBILITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: true,
      };

    case PUT_VISIBILITY_SUCCESS:
      return {
        ...state,
        isVisible: action.payload,
        error: false,
        loading: false,
      };

    case PUT_VISIBILITY_FALUIRE:
      return {
        ...state,
        loading: false,
        isVisible: null,
        error: action.payload,
      };


    default: return state
  }
}

export default reducer
