import {
  POST_GAME_REQUEST,
  POST_GAME_SUCCESS,
  POST_GAME_FALUIRE,

  GET_GAME_REQUEST,
  GET_GAME_SUCCESS,
  GET_GAME_FALUIRE,

  GET_GAME_BY_ID_REQUEST,
  GET_GAME_BY_ID_SUCCESS,
  GET_GAME_BY_ID_FALUIRE,

  UPDATE_GAME_REQUEST,
  UPDATE_GAME_SUCCESS,
  UPDATE_GAME_FALUIRE,

  PUT_VISIBILITY_REQUEST,
  PUT_VISIBILITY_SUCCESS,
  PUT_VISIBILITY_FALUIRE,
} from './addGameTypes'

const initialState = {
  loading: false,
  error: '',
  ToogleData: false,
  postGame: null,
  getByIdGame: null,
  getGame: [],
  updateGame: null

}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case POST_GAME_REQUEST: return {
      ...state,
      loading: true,

    }

    case POST_GAME_SUCCESS: return {
      ...state,
      postGame: action.payload,
      error: '',
      loading: false,
    }

    case POST_GAME_FALUIRE: return {
      ...state,
      loading: false,
      postGame: null,
      error: action.payload,
    }


    case GET_GAME_REQUEST: return {
      ...state,
      loading: true,

    }

    case GET_GAME_SUCCESS: return {
      ...state,
      getGame: action.payload,
      error: '',
      loading: false,
    }

    case GET_GAME_FALUIRE: return {
      ...state,
      loading: false,
      getGame: null,
      error: action.payload,
    }

    case GET_GAME_BY_ID_REQUEST: return {
      ...state,
      loading: true,

    }

    case GET_GAME_BY_ID_SUCCESS: return {
      ...state,
      getByIdGame: action.payload,
      error: '',
      loading: false,
    }

    case GET_GAME_BY_ID_FALUIRE: return {
      ...state,
      loading: false,
      getByIdGame: null,
      error: action.payload,
    }


    case UPDATE_GAME_REQUEST: return {
      ...state,
      loading: true,
      ToogleData: false
    }

    case UPDATE_GAME_SUCCESS: return {
      ...state,
      updateGame: action.payload,
      error: '',
      ToogleData: true,
      loading: false,
    }

    case UPDATE_GAME_FALUIRE: return {
      ...state,
      loading: false,
      updateGame: null,
      ToogleData: false,
      error: action.payload,
    }

    case PUT_VISIBILITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: true,
      };

    case PUT_VISIBILITY_SUCCESS:
      return {
        ...state,
        isVisible: action.payload,
        error: false,
        loading: false,
      };

    case PUT_VISIBILITY_FALUIRE:
      return {
        ...state,
        loading: false,
        isVisible: null,
        error: action.payload,
      };

    default: return state
  }
}

export default reducer
