import {
  FETCH_CATEGORY_TYPE_LIST_REQUEST,
  FETCH_CATEGORY_TYPE_LIST_SUCCESS,
  FETCH_CATEGORY_TYPE_LIST_FALUIRE,
} from './categoryTypeListTypes'

const initialState = {
  loading: false,
  allCategoryList: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_CATEGORY_TYPE_LIST_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_CATEGORY_TYPE_LIST_SUCCESS : return {
      ...state,
      allCategoryList: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_CATEGORY_TYPE_LIST_FALUIRE : return {
      ...state,
      loading: false,
      allCategoryList: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
