import {
  FETCH_ADD_BRAND_PLATFORM_REQUEST,
  FETCH_ADD_BRAND_PLATFORM_SUCCESS,
  FETCH_ADD_BRAND_PLATFORM_FALUIRE,

  FETCH_Get_BRAND_PLATFORM_REQUEST,
  FETCH_Get_BRAND_PLATFORM_SUCCESS,
  FETCH_Get_BRAND_PLATFORM_FALUIRE,
} from './addBrandPlatformTypes'

const initialState = {
  loading: false,
  addPlatformBrand: null,
  error: '',
  getbrand: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_ADD_BRAND_PLATFORM_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_ADD_BRAND_PLATFORM_SUCCESS: return {
      ...state,
      addPlatformBrand: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_ADD_BRAND_PLATFORM_FALUIRE: return {
      ...state,
      loading: false,
      addPlatformBrand: null,
      error: action.payload,
    }



    case FETCH_Get_BRAND_PLATFORM_REQUEST: return {
      ...state,
      loading: true,
      getbrand: null,
    }

    case FETCH_Get_BRAND_PLATFORM_SUCCESS: return {
      ...state,
      getbrand: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_Get_BRAND_PLATFORM_FALUIRE: return {
      ...state,
      loading: false,
      getbrand: null,
      error: action.payload,
    }



    default: return state
  }
}

export default reducer
