import {
  FETCH_Survay_CATEGORY_REQUEST,
  FETCH_Survay_CATEGORY_SUCCESS,
  FETCH_Survay_CATEGORY_FALUIRE,
  POST_SURVEY_FORM_REQUEST,
  POST_SURVEY_FORM_SUCCESS,
  POST_SURVEY_FORM_FALUIRE,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_FALUIRE,
  POST_TAGS_REQUEST,
  POST_TAGS_SUCCESS,
  POST_TAGS_FALUIRE,
  GET_MANAGE_SURVEY_REQUEST,
  GET_MANAGE_SURVEY_SUCCESS,
  GET_MANAGE_SURVEY_FALUIRE,
  POST_SURVEY_REQUEST,
  POST_SURVEY_SUCCESS,
  POST_SURVEY_FALUIRE,
  PUT_VISIBILITY_REQUEST,
  PUT_VISIBILITY_SUCCESS,
  PUT_VISIBILITY_FALUIRE,
  DELETE_MANAGE_SURVAY_REQUEST,
  DELETE_MANAGE_SURVAY_SUCCESS,
  DELETE_MANAGE_SURVAY_FAILURE,
  GET_COMPLETED_SURVAY_REQUEST,
  GET_COMPLETED_SURVAY_SUCCESS,
  GET_COMPLETED_SURVAY_FAIL,
  AD_SURVEY_HOME_PAGE_REQUEST,
  AD_SURVEY_HOME_SUCCESS,
  AD_SURVEY_HOME_PAGE_FALUIRE,
  GET_SURVEY_REQUEST,
  GET_SURVEY_SUCCESS,
  GET_SURVEY_FORM_REQUEST,
  GET_SURVEY_FORM_SUCCESS,
  GET_SURVEY_FORM_FAILED,
  GET_SURVEY_TAGS_REQUEST,
  GET_SURVEY_TAGS_SUCCESS,
  GET_SURVEY_TAGS_FAILED,
} from "./addsurvayTypes";

const initialState = {
  loading: true,
  list: null,
  error: false,
  formData: null,
  getdata: false,
  getTags: [],
  getManageSurvey: [],
  postSurveyForm: null,
  isVisible: null,
  postTags: null,
  deleteSurvey: null,
  getTagsForManage: [],
  loadingsurvey: false,
  completedSurvey: false,
  addtoHomepageSurvey: null,
  surveyData: null,
  surveyFormData: null,
  noFormData: true,
  surveyTags: [],
  next: null,
  previous: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_Survay_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_Survay_CATEGORY_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error: false,
        loading: false,
      };

    case FETCH_Survay_CATEGORY_FALUIRE:
      return {
        ...state,
        loading: false,
        list: null,
        error: action.payload,
      };

    case POST_SURVEY_FORM_REQUEST:
      return {
        ...state,
        loadingsurvey: true,
        error: false,
        getdata: false,
      };

    case POST_SURVEY_FORM_SUCCESS:
      return {
        ...state,
        formData: action.payload,
        error: false,
        loadingsurvey: false,
        getdata: true,
      };

    case POST_SURVEY_FORM_FALUIRE:
      return {
        ...state,
        loadingsurvey: action.payload,
        formData: null,
        error: true,
        getdata: false,
      };

    case GET_TAGS_REQUEST:
      return {
        ...state,
        //loading: true,
        error: true,
      };

    case GET_TAGS_SUCCESS:
      return {
        ...state,
        getTags: action.payload,
        error: false,
        //loading: false,
      };

    case GET_TAGS_FALUIRE:
      return {
        ...state,
        //loading: false,
        getTags: null,
        error: action.payload,
      };

    case POST_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: true,
      };

    case POST_TAGS_SUCCESS:
      return {
        ...state,
        postTags: action.payload,
        error: false,
        loading: false,
      };

    case POST_TAGS_FALUIRE:
      return {
        ...state,
        loading: false,
        postTags: null,
        error: action.payload,
      };

    case GET_MANAGE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_MANAGE_SURVEY_SUCCESS:
      return {
        ...state,
        getManageSurvey: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        error: false,
        loading: false,
      };

    case GET_MANAGE_SURVEY_FALUIRE:
      return {
        ...state,
        loading: false,
        getManageSurvey: null,
        next: null,
        previous: null,
        error: action.payload,
      };

    case POST_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        error: true,
      };

    case POST_SURVEY_SUCCESS:
      return {
        ...state,
        postSurveyForm: action.payload,
        error: false,
        loading: false,
        noFormData: false,
      };

    case POST_SURVEY_FALUIRE:
      return {
        ...state,
        loading: false,
        postSurveyForm: null,
        error: action.payload,
      };

    // =================delete=====================

    case DELETE_MANAGE_SURVAY_REQUEST:
      return {
        ...state,
        loading: true,
        error: true,
      };

    case DELETE_MANAGE_SURVAY_SUCCESS:
      return {
        ...state,
        deleteSurvey: action.payload,
        error: false,
        loading: false,
      };

    case DELETE_MANAGE_SURVAY_FAILURE:
      return {
        ...state,
        loading: false,
        deleteSurvey: null,
        error: action.payload,
      };

    // ================================
    case PUT_VISIBILITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: true,
      };

    case PUT_VISIBILITY_SUCCESS:
      return {
        ...state,
        isVisible: action.payload,
        error: false,
        loading: false,
      };

    case PUT_VISIBILITY_FALUIRE:
      return {
        ...state,
        loading: false,
        isVisible: null,
        error: action.payload,
      };
    case GET_COMPLETED_SURVAY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPLETED_SURVAY_SUCCESS:
      return {
        ...state,
        loading: false,
        completedSurvey: action.payload,
      };
    case GET_COMPLETED_SURVAY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AD_SURVEY_HOME_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AD_SURVEY_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        addtoHomepageSurvey: action.payload,
      };
    case AD_SURVEY_HOME_PAGE_FALUIRE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        surveyData: null,
      }
    case GET_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        surveyData: action.payload,
      }
    case GET_SURVEY_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        surveyFormData: null,
        noFormData: true,
      }

    case GET_SURVEY_FORM_SUCCESS:
      let noForm = false;
      if(Object.keys(action.payload.form).length === 0){
        noForm = true;
      }
      return {
        ...state,
        loading: false,
        surveyFormData: action.payload.form,
        noFormData: noForm,
      }

    case GET_SURVEY_FORM_FAILED:
      return {
        ...state,
        loading: false,
        surveyFormData: null,
        noFormData: true,
      }

    case GET_SURVEY_TAGS_REQUEST:
      return {
        ...state,
        //loading: true,
        surveyTags: [],
        error: true,
      };

    case GET_SURVEY_TAGS_SUCCESS:
      return {
        ...state,
        surveyTags: action.payload,
        error: false,
        //loading: false,
      };

    case GET_SURVEY_TAGS_FAILED:
      return {
        ...state,
        //loading: false,
        surveyTags: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
