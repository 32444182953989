export const POST_GAME_REQUEST = 'POST_GAME_REQUEST'
export const POST_GAME_SUCCESS = 'POST_GAME_SUCCESS'
export const POST_GAME_FALUIRE = 'POST_GAME_FALUIRE'

/**Get GAME */

export const GET_GAME_REQUEST = 'GET_GAME_REQUEST'
export const GET_GAME_SUCCESS = 'GET_GAME_SUCCESS'
export const GET_GAME_FALUIRE = 'GET_GAME_FALUIRE'
/**FETCH BY GAMES */

export const GET_GAME_BY_ID_REQUEST = 'GET_GAME_BY_ID_REQUEST'
export const GET_GAME_BY_ID_SUCCESS = 'GET_GAME_BY_ID_SUCCESS'
export const GET_GAME_BY_ID_FALUIRE = 'GET_GAME_BY_ID_FALUIRE'

/**UPDATE GAME */
export const UPDATE_GAME_REQUEST = 'UPDATE_GAME_REQUEST'
export const UPDATE_GAME_SUCCESS = 'UPDATE_GAME_SUCCESS'
export const UPDATE_GAME_FALUIRE = 'UPDATE_GAME_FALUIRE'


/**DELETE GAME */
export const DELETE_GAME_REQUEST = 'DELETE_GAME_REQUEST'
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS'
export const DELETE_GAME_FALUIRE = 'DELETE_GAME_FALUIRE'

/**PUT VISIBILITY */
export const PUT_VISIBILITY_REQUEST = "PUT_VISIBILITY_REQUEST";
export const PUT_VISIBILITY_SUCCESS = "PUT_VISIBILITY_SUCCESS";
export const PUT_VISIBILITY_FALUIRE = "PUT_VISIBILITY_FALUIRE";

