export const FETCH_PRODUCT_TRAIL_REQUEST = 'FETCH_PRODUCT_TRAIL_REQUEST'
export const FETCH_PRODUCT_TRAIL_SUCCESS = ' FETCH_PRODUCT_TRAIL_SUCCESS'
export const FETCH_PRODUCT_TRAIL_FALUIRE = 'FETCH_PRODUCT_TRAIL_FALUIRE'

export const FETCH_SURVAY_REQUEST = 'FETCH_SURVAY_REQUEST'
export const FETCH_SURVAY_SUCCESS = ' FETCH_SURVAY_SUCCESS'
export const FETCH_SURVAY_FALUIRE = 'FETCH_SURVAY_FALUIRE'

export const FETCH_GAME_REQUEST = 'FETCH_GAME_REQUEST'
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS'
export const FETCH_GAME_FALUIRE = 'FETCH_GAME_FALUIRE'