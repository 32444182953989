
export const GET_SURVEY_INITIAL_REQUEST = 'GET_SURVEY_INITIAL_REQUEST'
export const GET_SURVEY_INITIAL_SUCCESS = 'GET_SURVEY_INITIAL_SUCCESS'
export const GET_SURVEY_INITIAL_FALUIRE = 'GET_SURVEY_INITIAL_FALUIRE'


export const POST_SURVEY_INITIAL_REQUEST = 'POST_SURVEY_INITIAL_REQUEST'
export const POST_SURVEY_INITIAL_SUCCESS = 'POST_SURVEY_INITIAL_SUCCESS'
export const POST_SURVEY_INITIAL_FALUIRE = 'POST_SURVEY_INITIAL_FALUIRE'


export const GET_SURVAY_PHASE_TWO_REQUEST = 'GET_SURVAY_PHASE_TWO_REQUEST'
export const GET_SURVAY_PHASE_TWO_SUCCESS = 'GET_SURVAY_PHASE_TWO_SUCCESS'
export const GET_SURVAY_PHASE_TWO_FALUIRE = 'GET_SURVAY_PHASE_TWO_FALUIRE'


export const POST_SURVEY_FINAL_SUBMITION_REQUEST = 'POST_SURVEY_FINAL_SUBMITION_REQUEST'
export const POST_SURVEY_FINAL_SUBMITION_SUCCESS = 'POST_SURVEY_FINAL_SUBMITION_SUCCESS'
export const POST_SURVEY_FINAL_SUBMITION_FALUIRE = 'POST_SURVEY_FINAL_SUBMITION_FALUIRE'

