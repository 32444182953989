import {GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FALUIRE} from "./types"

  
  const initialState = {
    loading: false,
   getProduct: null,
    error: '',
  }
  
  const reducer = (state = initialState, action) => {
 
    switch (action.type) {
      case GET_USER_REQUEST : return {
        ...state,
        loading: true, 
      }
  
      case GET_USER_SUCCESS: return {
        ...state,
        getProduct: action.payload,
        error: '',
        loading: false,
      }
  
      case GET_USER_FALUIRE: return {
        ...state,
        loading: false,
        getProduct: null,
        error: action.payload,
      }
      default : return state
    }
  }
  
  export default reducer
  