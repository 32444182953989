export const GET_PRIVACY_POLICY_REQUEST = 'GET_PRIVACY_POLICY_REQUEST'
export const GET_PRIVACY_POLICY_SUCCESS = 'GET_PRIVACY_POLICY_SUCCESS'
export const GET_PRIVACY_POLICY_FALUIRE = 'GET_PRIVACY_POLICY_FALUIRE'


export const GET_ABOUT_US_REQUEST = 'GET_ABOUT_US_REQUEST'
export const GET_ABOUT_US_SUCCESS = 'GET_ABOUT_US_SUCCESS'
export const GET_ABOUT_US_FALUIRE = 'GET_ABOUT_US_FALUIRE'


export const GET_TERM_CONDITION_REQUEST = 'GET_TERM_CONDITION_REQUEST'
export const GET_TERM_CONDITION_SUCCESS = 'GET_TERM_CONDITION_SUCCESS'
export const GET_TERM_CONDITION_FALUIRE = 'GET_TERM_CONDITION_FALUIRE'

export const PUT_TERM_CONDITION_REQUEST = 'PUT_TERM_CONDITION_REQUEST'
export const PUT_TERM_CONDITION_SUCCESS = 'PUT_TERM_CONDITION_SUCCESS'
export const PUT_TERM_CONDITION_FALUIRE = 'PUT_TERM_CONDITION_FALUIRE'

export const PUT_PRIVACY_POLICY_REQUEST = 'PUT_PRIVACY_POLICY_REQUEST'
export const PUT_PRIVACY_POLICY_SUCCESS = 'PUT_PRIVACY_POLICY_SUCCESS'
export const PUT_PRIVACY_POLICY_FALUIRE = 'PUT_PRIVACY_POLICY_FALUIRE'


export const PUT_ABOUT_US_REQUEST = 'PUT_ABOUT_US_REQUEST'
export const PUT_ABOUT_US_SUCCESS = 'PUT_ABOUT_US_SUCCESS'
export const PUT_ABOUT_US_FALUIRE = 'PUT_ABOUT_US_FALUIRE'

