import {


  GET_SURVEY_INITIAL_REQUEST,
  GET_SURVEY_INITIAL_SUCCESS,
  GET_SURVEY_INITIAL_FALUIRE,

  POST_SURVEY_INITIAL_REQUEST,
  POST_SURVEY_INITIAL_SUCCESS,
  POST_SURVEY_INITIAL_FALUIRE,

  POST_SURVEY_FINAL_SUBMITION_REQUEST,
  POST_SURVEY_FINAL_SUBMITION_SUCCESS,
  POST_SURVEY_FINAL_SUBMITION_FALUIRE

} from './useraddsurvayTypes'

const initialState = {
  loading: false,
  initialSurveydata: null,
  questionData: null,
  error: false,
  finalsubmition: null

}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_SURVEY_INITIAL_REQUEST: return {
      ...state,
      loading: true,

    }

    case GET_SURVEY_INITIAL_SUCCESS: return {
      ...state,
      initialSurveydata: action.payload,
      error: false,
      loading: false,
    }

    case GET_SURVEY_INITIAL_FALUIRE: return {
      ...state,
      loading: false,
      initialSurveydata: null,
      error: action.payload,
    }




    case POST_SURVEY_INITIAL_REQUEST: return {
      ...state,
      loading: true,

    }

    case POST_SURVEY_INITIAL_SUCCESS: return {
      ...state,
      questionData: action.payload,
      error: false,
      loading: false,
    }

    case POST_SURVEY_INITIAL_FALUIRE: return {
      ...state,
      loading: false,
      questionData: null,
      error: action.payload,
    }


    case POST_SURVEY_FINAL_SUBMITION_REQUEST: return {
      ...state,
      loading: true,

    }

    case POST_SURVEY_FINAL_SUBMITION_SUCCESS: return {
      ...state,
      finalsubmition: action.payload,
      error: false,
      loading: false,
    }

    case POST_SURVEY_FINAL_SUBMITION_FALUIRE: return {
      ...state,
      loading: false,
      finalsubmition: null,
      error: action.payload,
    }


    default: return state
  }
}

export default reducer
