import wrapWithProvider from "./src/index";
import { navigate } from "gatsby";
import { trackPageView } from "./src/utils/analytics";

export const wrapRootElement = wrapWithProvider;

function checkIfUserIsLoggedIn() {
  try {
    const loginInfo = localStorage.getItem("loginInfo");
    if (!loginInfo) return false;

    const parsedLoginInfo = JSON.parse(loginInfo);
    const token = parsedLoginInfo?.userInfo?.userInfo?.token;

    return !!token; // Returns true if token exists and is not empty, false otherwise
  } catch (error) {
    console.error("Error checking login status:", error);
    return false; // Return false if there's any error in parsing or accessing the data
  }
}


export const onInitialClientRender = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const allParams = {};
  
  // Convert URLSearchParams to a plain object
  for (const [key, value] of urlParams.entries()) {
    allParams[key] = value;
  }

  // Store all params in localStorage
  if (Object.keys(allParams).length > 0) {
    localStorage.setItem("allParams", JSON.stringify(allParams));
  } else {
    localStorage.removeItem("allParams");
  }
};


export const onRouteUpdate = ({ location }) => {
  trackPageView();
  const storedParams = JSON.parse(localStorage.getItem("allParams") || "{}");
  const isUserLoggedIn = checkIfUserIsLoggedIn();

  if (!isUserLoggedIn && Object.keys(storedParams).length > 0) {
    const currentUrlParams = new URLSearchParams(location.search);
    let needsUpdate = false;

    // Check if any stored params are missing from current URL
    Object.entries(storedParams).forEach(([key, value]) => {
      const currentValue = currentUrlParams.get(key);
      if (currentValue !== value) {
        currentUrlParams.set(key, value);
        needsUpdate = true;
      }
    });

    // Only navigate if we need to update parameters
    if (needsUpdate) {
      const newUrl = `${location.pathname}?${currentUrlParams.toString()}`;
      navigate(newUrl, { replace: true });
    }
  }
};

