import {
  FETCH_ADD_TEMPLATE_REQUEST,
  FETCH_ADD_TEMPLATE_SUCCESS,
  FETCH_ADD_TEMPLATE_FALUIRE,

  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FALUIRE,

  GET_BY_ID_TEMPLATE_REQUEST,
  GET_BY_ID_TEMPLATE_SUCCESS,
  GET_BY_ID_TEMPLATE_FALUIRE,
} from './addTemplateTypes'

const initialState = {
  loading: false,
  addTemplates: null,
  getTemplate: [],
  getByIdTemplate: null,
  error: ''
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_ADD_TEMPLATE_REQUEST: return {
      ...state,
      loading: true,
    }

    case FETCH_ADD_TEMPLATE_SUCCESS: return {
      ...state,
      addTemplates: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_ADD_TEMPLATE_FALUIRE: return {
      ...state,
      loading: false,
      addTemplates: null,
      error: action.payload,
    }

    case GET_TEMPLATE_REQUEST: return {
      ...state,
      loading: true,
    }

    case GET_TEMPLATE_SUCCESS: return {
      ...state,
      getTemplate: action.payload,
      error: '',
      loading: false,
    }

    case GET_TEMPLATE_FALUIRE: return {
      ...state,
      loading: false,
      getTemplate: null,
      error: action.payload,
    }

    case GET_BY_ID_TEMPLATE_REQUEST: return {
      ...state,
      loading: true,
    }

    case GET_BY_ID_TEMPLATE_SUCCESS: return {
      ...state,
      getByIdTemplate: action.payload,
      error: '',
      loading: false,
    }

    case GET_BY_ID_TEMPLATE_FALUIRE: return {
      ...state,
      loading: false,
      getByIdTemplate: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
